/*
 * @Name         :
 * @Version      : 1.0.0
 * @Author       : Jaron Jia
 * @Company      : JaronSoft & DCSDATA
 * @Date         : 2021-05-21 19:16:35
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2024-01-01 01:14:09
 * @FilePath     : /SGIS.1-1-0.com.cn/v2/src/models/SiteManager.ts
 * Copyright(C) 2021,JaronSoft LLC.
 *  以下公司保留代码的一切权利
 * (C)2007-2021 扬州市佳融信息技术有限公司
 * (C)2015-2021 扬州运帷数据技术有限公司
 * 禁止将代码用于非上述公司的项目
 */

import { request } from "ice";

/**
 * SiteManager 模型定义
 * 使用 ICE 框架
 */
export default {
  // 定义模型的初始状态
  state: {
    ClassTreeList: [], // 类别树状列表
  },

  // 定义模型的 reducers
  reducers: {
    /**
     * 设置类别树状列表数据
     * @param prevState 当前状态
     * @param data 新的树状列表数据
     * @returns 更新后的状态
     */
    setClassTreedata(prevState, data) {
      return {
        ...prevState,
        ClassTreeList: data,
      };
    },
  },

  // 定义模型的 effects
  effects: (dispatch) => ({
    /**
     * 异步获取类别树状列表
     * @returns 无返回值
     */
    async fetchClassTreeAsync() {
      const data = await request.post(
        "/SiteManager/NewsCategory/GetTreeSelectClassList",
        {}
      );
      dispatch.SiteManager.setClassTreedata(data.data);
    },

    /**
     * 异步获取新闻类别树状列表
     * @param IsAddRoot 是否添加根节点，默认为 true
     * @returns 无返回值
     */
    async fetchNewsCategoryTreeAsync(IsAddRoot = true) {
      let RootNode = null;
      const data = await request.post("/SiteManager/NewsCategory/FullList", {
        outputType: 1,
        SysDictionaryListParam: {
          isRootOrLevel1: true,
          rootDictKey: "",
        },
      });
      let returnValue = data.data;
      if (IsAddRoot) {
        RootNode = [
          {
            label: "选择分类",
            value: "0",
            key: "0",
            selectable: true,
            children: returnValue.data,
          },
        ];
        dispatch.SiteManager.setClassTreedata(
          JSON.parse(JSON.stringify(RootNode))
        );
      } else {
        dispatch.SiteManager.setClassTreedata(
          JSON.parse(JSON.stringify(returnValue.data))
        );
      }
    },
  }),
};
