/*
 * @Name         : 
 * @Version      : 1.0.0
 * @Author       : Jaron Jia
 * @Company      : JaronSoft & DCSDATA
 * @Date         : 2023-11-28 12:09:00
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2023-11-28 12:17:09
 * @FilePath     : /SGIS.1-1-0.com.cn/v2/src/models/Organization.ts
 * Copyright(C) 2023,JaronSoft LLC.
 *  以下公司保留代码的一切权利
 * (C)2007-2023 扬州市佳融信息技术有限公司
 * (C)2015-2023 扬州运帷数据技术有限公司
 * 禁止将代码用于非上述公司的项目
 */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createModel } from 'ice';
import { request } from 'ice';

interface OrganizationState {
  DepartmentList: any[];
  DepartmentTreeList: any[]; // TreeSelect 支持的数据源
  RolesList: any[];
}

export default createModel({
  state: {
    DepartmentList: [],
    DepartmentTreeList: [], // TreeSelect 支持的数据源
    RolesList: [],
  } as OrganizationState,

  reducers: {
    setDeptdata(prevState: OrganizationState, data) {
      prevState.DepartmentList = data;
    },
    setDeptTreedata(prevState: OrganizationState, data) { // 生成 TreeSelect 支持的数据源，需要后台配合
      prevState.DepartmentTreeList = [{
        label: '请选择单位',
        value: '-1',
        selectable: false,
        children: data.data,
      }];
    },
    setRoledata(prevState: OrganizationState, data) {
      prevState.RolesList = data;
    },
  },

  effects: (dispatch) => ({
    async fetchDepartmentInfoAsync() {
      const data = await request.post('/OrganizationManage/Department/GetListJson', {});
      dispatch.Organization.setDeptdata(data.data);
    },
    async fetchDepartmentTreeAsync() {
      const data = await request.post('/OrganizationManage/Department/GetZtreeDepartmentListJson', {});
      dispatch.Organization.setDeptTreedata(data.data);
    },
    async fetchRolesInfoAsync() {
      const data = await request.post('/SystemManage/role/GetCtrlValuetList', {});
      dispatch.Organization.setRoledata(data.data);
    },
  }),
});
