/*
 * @Name         : 系统启动组件
 * @Version      : 1.0.0
 * @Author       : Jaron Jia
 * @Company      : JaronSoft & DCSDATA
 * @Date         : 2023-11-27 19:57:05
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2023-12-28 17:50:07
 * @FilePath     : /SGIS.1-1-0.com.cn/v2/src/app.ts
 * Copyright(C) 2023,JaronSoft LLC.
 *  以下公司保留代码的一切权利
 * (C)2007-2023 扬州市佳融信息技术有限公司
 * (C)2015-2023 扬州运帷数据技术有限公司
 * 禁止将代码用于非上述公司的项目
 */
import { refreshTokenRequest } from "@/utils/RefreshToken";
import { getCookie } from "@/utils/cookieutils";
import { Message } from "@alifd/next";
import { defineAuthConfig } from "@ice/plugin-auth/types";
import { defineRequestConfig } from "@ice/plugin-request/types";
import { defineStoreConfig } from "@ice/plugin-store/types";
import { defineAppConfig, defineDataLoader, history } from "ice";
import { fetchUserInfo } from "./services/user";
export default defineAppConfig(() => ({
  app: {
    rootId: "jaronsoft-container",
  },
}));

export const authConfig = defineAuthConfig(async (appData) => {
  const { userInfo = {} } = appData;
  if (userInfo.error) {
    // history?.push(`/login?redirect=${window.location.pathname}`);
  }
  return {
    initialAuth: {
      admin: userInfo.userType === "admin",
      platform: userInfo.userType === "platform",
      xz_fg: userInfo.userType === "xz_fg",
      xz_leader: userInfo.userType === "xz_leader",
      cun_leader: userInfo.userType === "cun_leader",
      basic_user: userInfo.userType === "basic_user",
      user: userInfo.userType === "user",
    },
  };
});

export const storeConfig = defineStoreConfig(async (appData) => {
  const { userInfo = {} } = appData;
  return {
    initialStates: {
      user: {
        currentUser: userInfo,
      },
    },
  };
});

export const requestConfig = defineRequestConfig(() => ({
  // 可选的，全局设置 request 是否返回 response 对象，默认为 false
  withFullResponse: false,
  baseURL: process.env.ICE_SERVICE_BASEURL,
  headers: {},
  interceptors: {
    request: {
      onConfig: (config) => {
        config.headers = {
          Authorization: `Bearer ${getCookie("ACCESS_TOKEN")}`,
        }; // 发送请求前：可以对 RequestConfig 做一些统一处理
        let isAuthToken = true; // 默认会验证token
        const skipAuth = [
          "refreshtoken",
          "api/pub.webclient",
          "login",
          "SiteManager/articles",
          "/Statics/",
          "logout",
          "sysinfo",
          "describecaptcha",
          "sendcaptchaccode",
          "sendsmscaptcha",
          "getoauth2url",
          "loginandbind",
          "checkcode",
          "sendcaptchacodebymq",
        ];

        isAuthToken = !skipAuth.some((item) =>
          config.url.toLowerCase().includes(item.toLowerCase())
        );

        // console.log("验证Token ? ", config.url.toLowerCase(), isAuthToken);
        if (isAuthToken) {
          // 如果请求中不是匿名请求，则尝试调用函数
          refreshTokenRequest().then((res) => {
            // refreshTokenRequest 会返回几个状态码 200,403,500
            if (res != null) {
              if (res === 401) {
                Message.warning("401:请重新登录");
                console.warn("安全认证时产生了内部服务器错误", res);
                history?.push(
                  `/auth/login?code=${res}&redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`
                );
              } else if (res === 500) {
                console.log("安全认证时产生了内部服务器错误", res);
                history?.push(`/${res}&redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`);
              }
            } else {
              Message.error("接口故障,如果多次出现请联系系统管理员");
              history?.push(`/400&redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`);
            }
          });
        }
        return config;
      },
      onError: (error) => {
        console.error("interceptors.request.onError", error);
        return Promise.reject(error);
      },
    },
    response: {
      onConfig: (response) => {
        // 请求成功：可以做全局的 toast 展示，或者对 response 做一些格式化
        // console.log("interceptors.response.onConfig", response);
        if (response.data) {
          if (response.data.statusCode === 200) {
            return response;
          } else if (response.data.statusCode === 401) {
            Message.error("登录超时 CODE:200401");
            history?.push(
              `/auth/login?code=${response.data.statusCode}&redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`
            );
          } else if (response.data.statusCode === 400) {
            Message.error("登录超时 CODE:200400");
            history?.push(
              `/auth/login?code=${response.data.statusCode}&redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`
            );
          } else if (response.data.statusCode === 10200100) {
            Message.error("登录已过期 CODE:10200100");
            history?.push(
              `/auth/login?code=${response.data.statusCode}&redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`
            );
          } else {
            Message.error("服务连接失败，请稍后重试");
            console.log("服务连接失败", response);
          }
          return null;
        } else {
          console.warn("请求异常 CODE:RESP-NULL", response);
          return null;
        }
      },
      onError: (error) => {
        // 请求出错：服务端返回错误状态码
        if (error.response && error.response.status) {
          switch (error.response.status) {
            case 401:
              Message.error("登录超时或无权限访问 CODE:401");
              // history?.push(
              //   `/feedback/${error.response.status}?redirect=${window.location.pathname}`
              // );
              break;
            case 400:
              Message.error(
                `登录超时或接口方法错误 CODE:${error.response.status}`
              );
              history?.push(
                `/feedback/${error.response.status}?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`
              );
              break;
            case 405:
              Message.error(
                `登录超时或接口方法错误 CODE:${error.response.status}`
              );
              // history?.push(
              //   `/feedback/${error.response.status}?redirect=${window.location.pathname}`
              // );
              break;
            case 500:
              Message.error("内部服务器错误 CODE:500");
              history?.push(
                `/feedback/${error.response.status}?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`
              );
              break;
            case 404:
              Message.error("无效的请求 CODE:404");
              history?.push(
                `/feedback/${error.response.status}?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`
              );
              break;
            default:
              Message.error(`未指明的错误(${error.response.status})`);
              history?.push(
                `/feedback/${error.response.status}?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`
              );
          }
        }
        console.error("interceptors.response.onError", error);
        return Promise.reject(error);
      },
    },
  },
}));

export const dataLoader = defineDataLoader(async () => {
  const userInfo = await getUserInfo();
  return {
    userInfo,
  };
});

async function getUserInfo() {
  try {
    const userInfo = await fetchUserInfo();
    return userInfo;
  } catch (error) {
    return {
      error,
    };
  }
}
