import module0 from '@ice/plugin-request/runtime';
                import module1 from '@ice/plugin-store/runtime';
                import module2 from '@ice/plugin-auth/runtime';
                
export const statics = [
  module0,
];
export const commons = [
  module1,
  module2,
];
