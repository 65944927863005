/*
 * @Name         : Cookie 应用
 * @Version      : 2.0.0
 * @Author       : Jaron Jia
 * @Company      : JaronSoft LLC & DCSDATA LLC
 * @Date         : 2023-11-28 08:46:00
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2023-12-03 12:29:20
 * @FilePath     : /SGIS.1-1-0.com.cn/v2/src/utils/cookieutils.ts
 * Copyright(C) 2023,JaronSoft LLC.
 *  以下公司保留代码的一切权利
 * (C)2007-2023 扬州市佳融信息技术有限公司
 * (C)2015-2023 扬州运帷数据技术有限公司
 * 禁止将代码用于非上述公司的项目
 * 2020/02/27 修改了非中文键值不需要 encode 的判断
 * 2023/12/03 cookie 写入path为/
 */

import { decrypt, encrypt } from "@/utils/crypt";

/**
 * GetCookieFunction 接口定义了 getCookie 函数的签名。
 * @param {string} name - 要获取的 Cookie 的名称。
 * @param {boolean} isCrypt - 指示是否对获取的 Cookie 值进行解密。
 * @returns {string | null} - 如果找到指定名称的 Cookie，则返回其值；如果未找到，则返回 null。
 */
interface GetCookieFunction {
  (name: string, isCrypt?: boolean): string | null;
}

/**
 * SetCookieFunction 接口定义了 setCookie 函数的签名。
 * @param {string} cookieName - 要设置的 Cookie 的名称。
 * @param {string} cookieValue - 要设置的 Cookie 的值。如果值包含中文，则会被编码。
 * @param {number} expiredays - Cookie 的过期时间（以天为单位）。
 * @param {boolean} [isCrypt=false] - 指示是否对 Cookie 值进行加密。
 */
interface SetCookieFunction {
  (
    cookieName: string,
    cookieValue: string,
    expiredays: number,
    isCrypt?: boolean
  ): void;
}

/**
 * DelCookieFunction 接口定义了 delCookie 函数的签名。
 * @param {string} name - 要删除的 Cookie 的名称。
 */
interface DelCookieFunction {
  (name: string): void;
}

/**
 * @Name 获取Cookie值
 * @Description 根据提供的名称获取Cookie值。如果isCrypt为真，则解密Cookie值。
 * @Params {string} name - 要获取的Cookie的名称。
 * @Params {boolean} isCrypt - 指示是否需要对Cookie值进行解密。
 * @returns {string|null} 返回Cookie的值，如果不存在则返回null。
 */
export const getCookie: GetCookieFunction = (name, isCrypt = false) => {
  let arr;
  const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`);
  if ((arr = document.cookie.match(reg))) {
    if (isCrypt) return decrypt(decodeURIComponent(arr[2]));
    return decodeURIComponent(arr[2]);
  } else {
    return null;
  }
};

/**
 * @Name: 设置cookie
 * @Description: 包含中文的cookie将被encode,以兼容safari
 * @Params: 不能包含特殊字符，如引号，分号等
 * @param {*} cookieName 键名
 * @param {*} cookieValue 键值
 * @param {*} expiredays 销毁时限(天)
 */
export const setCookie: SetCookieFunction = (
  cookieName,
  cookieValue,
  expiredays,
  isCrypt = false
) => {
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  if (/.*[\u4e00-\u9fa5]+.*$/.test(cookieValue)) {
    //如果有汉字，要编码
    cookieValue = encodeURIComponent(cookieValue);
  }
  if (isCrypt) cookieValue = encrypt(cookieValue);
  document.cookie = `${cookieName}=${cookieValue}${
    expiredays == null ? "" : `;path=/;expires=${exdate.toUTCString()}`
  }`;
};

/**
 * @Name 删除cookie
 * @Description 该函数用于删除一个指定的cookie。首先设置一个过去的时间点，然后获取要删除的cookie的当前值，
 *              并设置其过期时间为该过去的时间点，以此来删除cookie。
 * @param {*} name 要删除的cookie的键值名称。
 */
export const delCookie: DelCookieFunction = (name) => {
  const exp = new Date();
  exp.setTime(exp.getTime() - 1);
  const cval = getCookie(name, false);
  if (cval != null)
    document.cookie = `${name}=${cval};expires=${exp.toUTCString()}`;
};
