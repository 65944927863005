/*
 * @Name         :
 * @Version      : 1.0.0
 * @Author       : Jaron Jia
 * @Company      : JaronSoft & DCSDATA
 * @Date         : 2021-10-04 00:38:05
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2023-12-09 11:12:15
 * @FilePath     : /SGIS.1-1-0.com.cn/v2/src/store.ts
 * Copyright(C) 2021,JaronSoft LLC.
 *  以下公司保留代码的一切权利
 * (C)2007-2021 扬州市佳融信息技术有限公司
 * (C)2015-2021 扬州运帷数据技术有限公司
 * 禁止将代码用于非上述公司的项目
 */
import DataDic from "@/models/DataDic";
import Dictionary from "@/models/Dictionary";
import Organization from "@/models/Organization";
import SiteManager from "@/models/SiteManager";
import Statics from "@/models/Statics";
import user from "@/models/user";
import { createStore } from "ice";

const store = createStore({
  SiteManager,
  Statics,
  Organization,
  DataDic,
  user,
  Dictionary,
});

export default store;
