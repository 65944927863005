/*
 * @Name         : 自动刷新 Token 组件
 * @Version      : 2.0.0
 * @Author       : Jaron Jia
 * @Company      : JaronSoft LLC & DCSDATA LLC
 * @Date         : 2023-11-28 08:46:00
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2023-12-22 15:16:14
 * @FilePath     : /SGIS.1-1-0.com.cn/v2/src/utils/RefreshToken.ts
 * Copyright(C) 2023,JaronSoft LLC.
 *  以下公司保留代码的一切权利
 * (C)2007-2023 扬州市佳融信息技术有限公司
 * (C)2015-2023 扬州运帷数据技术有限公司
 * 禁止将代码用于非上述公司的项目
 * 经过 ChatGPT 优化过的版本
 * monent.js 废弃，改用 dayjs
 */

import { GetJWTTokenUserInfo } from "@/utils/";
import { getCookie, setCookie } from "@/utils/cookieutils";
import dayjs from "dayjs";
import { request } from "ice";

const updateBeforeExp = "20";
const isDebug = true;
let doFlag = false;

export async function refreshTokenRequest() {
  var jwtTokenUserInfo = GetJWTTokenUserInfo();

  if (doFlag) return 200;
  doFlag = true;
  try {
    const expireTime = dayjs(getCookie("EXPIRE_TIME"));
    const refreshTime = expireTime.subtract(parseInt(updateBeforeExp), "m");

    setCookie("NEXT_TOKEN_REFRESH_TIME", refreshTime.format(), 1);

    if (dayjs() <= refreshTime) {
      // logDebug(`[不更新TOKEN]当前时间${dayjs().format('HH:mm:ss')}，下次更新时间：${refreshTime.format('HH:mm:ss')} 到期时间：${expireTime.format('HH:mm:ss')}`);
      return resetDoFlag(200);
    }

    logDebug(`[更新TOKEN]过期时间：${expireTime.format("HH:mm:ss")}`);
    const respRefreshToken = await request.post("/auth/RefreshToken", {
      Id: jwtTokenUserInfo?.sub,
      AppUserType: jwtTokenUserInfo?.AppUserType,
      RefreshToken: getCookie("REFRESH_TOKEN"),
      ApiToken: jwtTokenUserInfo?.ApiToken,
    });

    if (respRefreshToken !== null && respRefreshToken.data !== "") {
      const serverToken = JSON.parse(respRefreshToken.data);
      logDebug(
        `过期时间变更: ${expireTime.format("HH:mm:ss")}==>${dayjs(
          serverToken.expire_time
        ).format("HH:mm:ss")}`
      );
      setCookie("ACCESS_TOKEN", serverToken.auth_token, 1);
      setCookie("REFRESH_TOKEN", serverToken.refresh_token, 1);
      setCookie("EXPIRE_TIME", serverToken.expire_time, 1);
      return resetDoFlag(200);
    }

    return resetDoFlag(respRefreshToken ? 401 : 500);
  } catch (err) {
    logDebug(`{BAD REQUEST, RefreshToken Is Null}/${err.message}} `);
    return resetDoFlag(401);
  }
}

function resetDoFlag(status) {
  doFlag = false;
  return status;
}

function logDebug(message) {
  if (isDebug) console.log(message);
}
