/*
 * @Name         : 
 * @Version      : 1.0.0
 * @Author       : Jaron Jia
 * @Company      : JaronSoft LLC & DCSDATA LLC
 * @Date         : 2023-11-28 12:09:00
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2023-12-22 14:52:35
 * @FilePath     : /SGIS.1-1-0.com.cn/v2/src/models/Statics.js
 * Copyright(C) 2023,JaronSoft LLC.
 *  以下公司保留代码的一切权利
 * (C)2007-2023 扬州市佳融信息技术有限公司
 * (C)2015-2023 扬州运帷数据技术有限公司
 * 禁止将代码用于非上述公司的项目
 */
import { request } from 'ice';


const delay = (time) => new Promise((resolve) => setTimeout(() => resolve(), time));

export default {
  state: {
    staticsTable: [], // 要支持对象比较难搞
  },

  reducers: {
    setdata(prevState, data) {
      prevState.staticsTable = data;
    },
  },

  effects: (dispatch) => ({
    async staticsDataAsync() { // Statics表数据加载
      await delay(3);
      const { data } = await request.post('/Statics/GetListJson', {});
      // console.log('HTR' + new Date().getTime(), data);
      dispatch.Statics.setdata(data.data);
    },
  }),
};
