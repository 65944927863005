/*
 * @Name         : 
 * @Version      : 1.0.0
 * @Author       : Jaron Jia
 * @Company      : JaronSoft & DCSDATA
 * @Date         : 2023-11-28 12:09:00
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2023-11-28 12:09:03
 * @FilePath     : /SGIS.1-1-0.com.cn/v2/src/models/DataDic.js
 * Copyright(C) 2023,JaronSoft LLC.
 *  以下公司保留代码的一切权利
 * (C)2007-2023 扬州市佳融信息技术有限公司
 * (C)2015-2023 扬州运帷数据技术有限公司
 * 禁止将代码用于非上述公司的项目
 */
import { request } from 'ice';

// src/models/user.ts
export default {
  state: {
    newsTypeList: [], // 新闻类型
  },
  reducers: {
    setNewsTypedata(prevState, data) {
      prevState.newsTypeList = data;
    },
  },
  effects: (dispatch) => ({
    async fetchNewsTypeAsync() {
      const data = await request.post('/OrganizationManage/DataDictDetail/GetDetailDicByTypetList', { dictType: 'NewsType' });

      dispatch.DataDic.setNewsTypedata(data.data.data);
    },
  }),
};
