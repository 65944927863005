/*
 * @Name         : Dictionary 模型
 * @Version      : 1.0.0
 * @Author       : Jaron Jia
 * @Company      : JaronSoft LLC & DCSDATA LLC
 * @Date         : 2023-12-09 10:16:51
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2023-12-30 22:02:15
 * @FilePath     : /SGIS.1-1-0.com.cn/v2/src/models/Dictionary.ts
 * Copyright(C) 2023,JaronSoft LLC.
 * 以下公司保留代码的一切权利
 * (C)2007-2023 扬州市佳融信息技术有限公司
 * (C)2015-2023 扬州运帷数据技术有限公司
 * 禁止将代码用于非上述公司的项目
 */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createModel } from "ice";
import { request } from "ice";

/*
 * @Name 字典状态接口
 * @Description 定义字典的状态
 */
interface DictionaryState {
  DictionaryList: any[];
  DictionaryTreeList: any[]; // TreeSelect 支持的数据源
}

/*
 * @Name 创建模型
 * @Description 使用 ice 框架的 createModel 方法定义字典模型
 */
export default createModel({
  /*
   * @Fields 字典模型的状态定义
   */
  state: {
    DictionaryList: [],
    DictionaryTreeList: [], // TreeSelect 支持的数据源
  } as DictionaryState,

  /*
   * @Name reducers
   * @Description 定义用于修改 state 的 reducer 函数
   */
  reducers: {
    /*
     * @Name setDictionarydata
     * @Description 设置字典数据
     * @Params prevState: DictionaryState, data: any
     */
    setDictionarydata(prevState: DictionaryState, data) {
      return {
        ...prevState,
        DictionaryList: data.data,
      };
    },
    /*
     * @Name setDictionaryTreedata
     * @Description 设置字典树数据
     * @Params prevState: DictionaryState, data: any
     */
    setDictionaryTreedata(prevState: DictionaryState, data) {
      // 生成 TreeSelect 支持的数据源，需要后台配合
      return {
        ...prevState,
        DictionaryTreeList: [
          {
            label: "首级(设置为主分类)",
            value: "0",
            key: "0",
            selectable: true,
            children: data.data,
          },
        ],
      };
    },
  },

  /*
   * @Name effects
   * @Description 定义异步操作和逻辑
   */
  effects: (dispatch) => ({
    /*
     * @Name fetchDictionaryListAsync
     * @Description 异步获取字典列表
     */
    async fetchDictionaryListAsync() {
      const data = await request.post("/SystemManage/Dictionary/FullList", {
        outputType: 0,
        SysDictionaryListParam: {
          isRootOrLevel1: true,
          rootDictKey: "",
        },
      });
      dispatch.Dictionary.setDictionarydata(data.data);
    },
    /*
     * @Name fetchDictionaryQueryListAsync
     * @Description 根据 DictKey 异步查询字典列表
     * @Params DictKey: string
     */
    async fetchDictionaryQueryListAsync(DictKey) {
      const data = await request.post("/SystemManage/Dictionary/FullList", {
        outputType: 1,
        SysDictionaryListParam: {
          isRootOrLevel1: true,
          rootDictKey: DictKey,
        },
      });
      dispatch.Dictionary.setDictionarydata(data.data);
    },
    /*
     * @Name fetchDictionaryTreeAsync
     * @Description 异步获取字典树
     */
    async fetchDictionaryTreeAsync() {
      //此方法只保留了 key,value,label,children
      const data = await request.post("/SystemManage/Dictionary/FullList", {
        outputType: 1,
        SysDictionaryListParam: {
          isRootOrLevel1: true,
          rootDictKey: "",
        },
      });
      dispatch.Dictionary.setDictionaryTreedata(data.data);
    },
  }),
});
