/*
 * @Name         :
 * @Version      : 1.0.0
 * @Author       : Jaron Jia
 * @Company      : JaronSoft & DCSDATA
 * @Date         : 2023-03-06 17:26:22
 * @LastEditors  : Jaron Jia
 * @LastEditTime : 2023-12-23 11:40:44
 * @FilePath     : /SGIS.1-1-0.com.cn/v2/src/utils/index.ts
 * Copyright(C) 2023,JaronSoft LLC.
 *  以下公司保留代码的一切权利
 * (C)2007-2023 扬州市佳融信息技术有限公司
 * (C)2015-2023 扬州运帷数据技术有限公司
 * 禁止将代码用于非上述公司的项目
 */

import { request } from "ice";
import { jwtDecode } from 'jwt-decode';
import { getCookie } from "./cookieutils";

/**
 * @Name: 应用程序初始化
 * @Description:
 * @Params:
 * @Returns:
 */
export async function Init() {
  const dataResponse = await request.get("/api/Init");
  if (dataResponse.data.tag === 1) {
  }
}

/**
 * @Name: 获得用户信息
 * @Description: 从 cookie 中获取名为 "ACCESS_TOKEN" 的 JWT token，并对其进行解码。如果 token 存在且有效，返回解码后的 JWTToken 对象；如果无效或不存在，则返回 null。
 * @Params: 无
 * @Returns: {JWTToken | null} - 解码后的用户信息或者在无效/不存在的情况下返回 null
 */
export function GetJWTTokenUserInfo(): JWTToken | null {
  const token = getCookie("ACCESS_TOKEN");
  if (token) {
    try {
      // 使用 jwtDecode 来解码 token
      const decodedToken: JWTToken = jwtDecode<JWTToken>(token);
      // 返回解码后的 JWTToken 对象
      return decodedToken;
    } catch (error) {
      console.error("Token 解码失败", error);
    }
  }

  // 如果没有 token 或解码失败，返回 null
  return null;
}

/**
 * @Name GetJWTTokenInfo
 * @Description 解析并返回JWT token的信息
 * @Params {string} token - 要解析的JWT token字符串
 * @returns {JWTToken | null} 解码后的JWTToken对象，如果解码失败或没有token则返回null
 */
export function GetJWTTokenInfo(token: string): JWTToken | null {
  if (token) {
    try {
      // 使用 jwtDecode 来解码 token
      const decodedToken: JWTToken = jwtDecode<JWTToken>(token);
      // 返回解码后的 JWTToken 对象
      return decodedToken;
    } catch (error) {
      console.error("Token 解码失败", error);
    }
  }

  // 如果没有 token 或解码失败，返回 null
  return null;
}

/**
 * @Name: JWTToken 接口
 * @Description: 定义解码后的 JWT (JSON Web Token) 的数据结构。这个接口包括了一系列的标准和自定义声明（claims），用于表示 token 的各种信息，如发行者、主题、有效期等。
 * @Fields:
 *   - ApiToken: 自定义声明，可能用于存储 API 令牌。
 *   - AppUserType: 自定义声明，表示用户的类型或角色。
 *   - aud (Audience): 标准声明，JWT 的预期接收者。
 *   - exp (Expiration Time): 标准声明，JWT 的过期时间（Unix 时间戳）。
 *   - "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": 自定义声明，用于存储特定的身份信息。
 *   - iat (Issued At): 标准声明，JWT 的发行时间（Unix 时间戳）。
 *   - iss (Issuer): 标准声明，JWT 的发行者。
 *   - jti (JWT ID): 标准声明，JWT 的唯一标识符。
 *   - nbf (Not Before): 标准声明，定义 JWT 生效的起始时间。
 *   - sub (Subject): 标准声明，JWT 的主题，通常是用户的唯一标识符。
 */
interface JWTToken {
  ApiToken: string;
  AppUserType: string;
  aud: string; // Audience
  exp: number; // Expiration Time
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
  iat: number; // Issued At
  iss: string; // Issuer
  jti: string; // JWT ID
  nbf: number; // Not Before
  sub: string; // Subject
}
