import { createRouteLoader, WrapRouteComponent, RouteErrorComponent } from '@ice/runtime';
import type { CreateRoutes } from '@ice/runtime';
const createRoutes: CreateRoutes = ({
  requestContext,
  renderMode,
}) => ([
  {
    path: 'Feedback/unknow',
    async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_feedback-unknow" */ '@/pages/Feedback/unknow');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Feedback/unknow',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Feedback/unknow',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
    errorElement: <RouteErrorComponent />,
    componentName: 'feedback-unknow',
    index: undefined,
    id: 'Feedback/unknow',
    exact: true,
    exports: ["default","pageConfig"],
  },{
    path: 'InMail',
    async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_inmail-layout" */ '@/pages/InMail/layout');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'InMail/layout',
          isLayout: true,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'InMail/layout',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
    errorElement: <RouteErrorComponent />,
    componentName: 'inmail-layout',
    index: undefined,
    id: 'InMail/layout',
    exact: true,
    exports: ["default"],
    layout: true,
    children: [{
      path: 'List/ItemList',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_inmail-list-itemlist" */ '@/pages/InMail/List/ItemList');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'InMail/List/ItemList',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'InMail/List/ItemList',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'inmail-list-itemlist',
      index: undefined,
      id: 'InMail/List/ItemList',
      exact: true,
      exports: ["default"],
    },{
      path: 'List',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_inmail-list-index" */ '@/pages/InMail/List/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'InMail/List',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'InMail/List',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'inmail-list-index',
      index: true,
      id: 'InMail/List',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Post',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_inmail-post-index" */ '@/pages/InMail/Post/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'InMail/Post',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'InMail/Post',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'inmail-post-index',
      index: true,
      id: 'InMail/Post',
      exact: true,
      exports: ["default"],
    },{
      path: 'View/*',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_inmail-view-$" */ '@/pages/InMail/View/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'InMail/View/*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'InMail/View/*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'inmail-view-$',
      index: undefined,
      id: 'InMail/View/*',
      exact: true,
      exports: ["default"],
    },]
  },{
    path: 'success',
    async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_success-index" */ '@/pages/success/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'success',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'success',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
    errorElement: <RouteErrorComponent />,
    componentName: 'success-index',
    index: true,
    id: 'success',
    exact: true,
    exports: ["default","pageConfig"],
  },{
    path: 'Admin',
    async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-layout" */ '@/pages/Admin/layout');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/layout',
          isLayout: true,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/layout',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
    errorElement: <RouteErrorComponent />,
    componentName: 'admin-layout',
    index: undefined,
    id: 'Admin/layout',
    exact: true,
    exports: ["default"],
    layout: true,
    children: [{
      path: 'Organization/Position/PositionDialogOperation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-organization-position-positiondialogoperation" */ '@/pages/Admin/Organization/Position/PositionDialogOperation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Organization/Position/PositionDialogOperation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Organization/Position/PositionDialogOperation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-organization-position-positiondialogoperation',
      index: undefined,
      id: 'Admin/Organization/Position/PositionDialogOperation',
      exact: true,
      exports: ["default"],
    },{
      path: 'Organization/Department/DialogOperation_dept',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-organization-department-dialogoperation_dept" */ '@/pages/Admin/Organization/Department/DialogOperation_dept');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Organization/Department/DialogOperation_dept',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Organization/Department/DialogOperation_dept',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-organization-department-dialogoperation_dept',
      index: undefined,
      id: 'Admin/Organization/Department/DialogOperation_dept',
      exact: true,
      exports: ["default"],
    },{
      path: 'SiteManager/NewsCategory/DialogOperation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-sitemanager-newscategory-dialogoperation" */ '@/pages/Admin/SiteManager/NewsCategory/DialogOperation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/SiteManager/NewsCategory/DialogOperation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/SiteManager/NewsCategory/DialogOperation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-sitemanager-newscategory-dialogoperation',
      index: undefined,
      id: 'Admin/SiteManager/NewsCategory/DialogOperation',
      exact: true,
      exports: ["default"],
    },{
      path: 'Organization/Department/DialogOperation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-organization-department-dialogoperation" */ '@/pages/Admin/Organization/Department/DialogOperation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Organization/Department/DialogOperation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Organization/Department/DialogOperation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-organization-department-dialogoperation',
      index: undefined,
      id: 'Admin/Organization/Department/DialogOperation',
      exact: true,
      exports: ["default"],
    },{
      path: 'Organization/Position/PositionOperation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-organization-position-positionoperation" */ '@/pages/Admin/Organization/Position/PositionOperation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Organization/Position/PositionOperation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Organization/Position/PositionOperation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-organization-position-positionoperation',
      index: undefined,
      id: 'Admin/Organization/Position/PositionOperation',
      exact: true,
      exports: ["default"],
    },{
      path: 'Organization/Department/Operation_dept',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-organization-department-operation_dept" */ '@/pages/Admin/Organization/Department/Operation_dept');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Organization/Department/Operation_dept',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Organization/Department/Operation_dept',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-organization-department-operation_dept',
      index: undefined,
      id: 'Admin/Organization/Department/Operation_dept',
      exact: true,
      exports: ["default"],
    },{
      path: 'ResourcesDic/Detail/DialogOperation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-resourcesdic-detail-dialogoperation" */ '@/pages/Admin/ResourcesDic/Detail/DialogOperation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/ResourcesDic/Detail/DialogOperation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/ResourcesDic/Detail/DialogOperation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-resourcesdic-detail-dialogoperation',
      index: undefined,
      id: 'Admin/ResourcesDic/Detail/DialogOperation',
      exact: true,
      exports: ["default"],
    },{
      path: 'SiteManager/NewsCategory/Operation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-sitemanager-newscategory-operation" */ '@/pages/Admin/SiteManager/NewsCategory/Operation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/SiteManager/NewsCategory/Operation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/SiteManager/NewsCategory/Operation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-sitemanager-newscategory-operation',
      index: undefined,
      id: 'Admin/SiteManager/NewsCategory/Operation',
      exact: true,
      exports: ["default"],
    },{
      path: 'Organization/Department/Operation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-organization-department-operation" */ '@/pages/Admin/Organization/Department/Operation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Organization/Department/Operation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Organization/Department/Operation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-organization-department-operation',
      index: undefined,
      id: 'Admin/Organization/Department/Operation',
      exact: true,
      exports: ["default"],
    },{
      path: 'SiteManager/NewsCategory/actions',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-sitemanager-newscategory-actions" */ '@/pages/Admin/SiteManager/NewsCategory/actions');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/SiteManager/NewsCategory/actions',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/SiteManager/NewsCategory/actions',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-sitemanager-newscategory-actions',
      index: undefined,
      id: 'Admin/SiteManager/NewsCategory/actions',
      exact: true,
      exports: ["AddData","CleanCache","DeleteData","SaveData","renderSort","renderStatus"],
    },{
      path: 'SiteManager/Articles/List',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-sitemanager-articles-list-index" */ '@/pages/Admin/SiteManager/Articles/List/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/SiteManager/Articles/List',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/SiteManager/Articles/List',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-sitemanager-articles-list-index',
      index: true,
      id: 'Admin/SiteManager/Articles/List',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'SiteManager/Articles/MoreList/*',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-sitemanager-articles-morelist-$" */ '@/pages/Admin/SiteManager/Articles/MoreList/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/SiteManager/Articles/MoreList/*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/SiteManager/Articles/MoreList/*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-sitemanager-articles-morelist-$',
      index: undefined,
      id: 'Admin/SiteManager/Articles/MoreList/*',
      exact: true,
      exports: ["default"],
    },{
      path: 'SiteManager/NewsCategory/Items',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-sitemanager-newscategory-items" */ '@/pages/Admin/SiteManager/NewsCategory/Items');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/SiteManager/NewsCategory/Items',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/SiteManager/NewsCategory/Items',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-sitemanager-newscategory-items',
      index: undefined,
      id: 'Admin/SiteManager/NewsCategory/Items',
      exact: true,
      exports: ["default"],
    },{
      path: 'SiteManager/NewsCategory',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-sitemanager-newscategory-index" */ '@/pages/Admin/SiteManager/NewsCategory/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/SiteManager/NewsCategory',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/SiteManager/NewsCategory',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-sitemanager-newscategory-index',
      index: true,
      id: 'Admin/SiteManager/NewsCategory',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Organization/Department',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-organization-department-index" */ '@/pages/Admin/Organization/Department/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Organization/Department',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Organization/Department',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-organization-department-index',
      index: true,
      id: 'Admin/Organization/Department',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'ResourcesDic/Detail/Operation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-resourcesdic-detail-operation" */ '@/pages/Admin/ResourcesDic/Detail/Operation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/ResourcesDic/Detail/Operation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/ResourcesDic/Detail/Operation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-resourcesdic-detail-operation',
      index: undefined,
      id: 'Admin/ResourcesDic/Detail/Operation',
      exact: true,
      exports: ["default"],
    },{
      path: 'ResourcesDic/DialogOperation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-resourcesdic-dialogoperation" */ '@/pages/Admin/ResourcesDic/DialogOperation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/ResourcesDic/DialogOperation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/ResourcesDic/DialogOperation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-resourcesdic-dialogoperation',
      index: undefined,
      id: 'Admin/ResourcesDic/DialogOperation',
      exact: true,
      exports: ["default"],
    },{
      path: 'SystemConfig/DialogOperation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-systemconfig-dialogoperation" */ '@/pages/Admin/SystemConfig/DialogOperation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/SystemConfig/DialogOperation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/SystemConfig/DialogOperation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-systemconfig-dialogoperation',
      index: undefined,
      id: 'Admin/SystemConfig/DialogOperation',
      exact: true,
      exports: ["default"],
    },{
      path: 'Organization/Gzc/List',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-organization-gzc-list-index" */ '@/pages/Admin/Organization/Gzc/List/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Organization/Gzc/List',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Organization/Gzc/List',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-organization-gzc-list-index',
      index: true,
      id: 'Admin/Organization/Gzc/List',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Organization/Position',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-organization-position-index" */ '@/pages/Admin/Organization/Position/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Organization/Position',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Organization/Position',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-organization-position-index',
      index: true,
      id: 'Admin/Organization/Position',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'SiteManager/Articles/Post/*',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-sitemanager-articles-post-$" */ '@/pages/Admin/SiteManager/Articles/Post/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/SiteManager/Articles/Post/*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/SiteManager/Articles/Post/*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-sitemanager-articles-post-$',
      index: undefined,
      id: 'Admin/SiteManager/Articles/Post/*',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'SiteManager/Articles/View/*',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-sitemanager-articles-view-$" */ '@/pages/Admin/SiteManager/Articles/View/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/SiteManager/Articles/View/*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/SiteManager/Articles/View/*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-sitemanager-articles-view-$',
      index: undefined,
      id: 'Admin/SiteManager/Articles/View/*',
      exact: true,
      exports: ["default"],
    },{
      path: 'Dictionary/DialogOperation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-dictionary-dialogoperation" */ '@/pages/Admin/Dictionary/DialogOperation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Dictionary/DialogOperation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Dictionary/DialogOperation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-dictionary-dialogoperation',
      index: undefined,
      id: 'Admin/Dictionary/DialogOperation',
      exact: true,
      exports: ["default"],
    },{
      path: 'Users/List/DialogOperation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-users-list-dialogoperation" */ '@/pages/Admin/Users/List/DialogOperation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Users/List/DialogOperation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Users/List/DialogOperation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-users-list-dialogoperation',
      index: undefined,
      id: 'Admin/Users/List/DialogOperation',
      exact: true,
      exports: ["default"],
    },{
      path: 'Function/DialogOperation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-function-dialogoperation" */ '@/pages/Admin/Function/DialogOperation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Function/DialogOperation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Function/DialogOperation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-function-dialogoperation',
      index: undefined,
      id: 'Admin/Function/DialogOperation',
      exact: true,
      exports: ["default"],
    },{
      path: 'Organization/Gzc/Post/*',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-organization-gzc-post-$" */ '@/pages/Admin/Organization/Gzc/Post/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Organization/Gzc/Post/*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Organization/Gzc/Post/*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-organization-gzc-post-$',
      index: undefined,
      id: 'Admin/Organization/Gzc/Post/*',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Organization/Gzc/View/*',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-organization-gzc-view-$" */ '@/pages/Admin/Organization/Gzc/View/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Organization/Gzc/View/*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Organization/Gzc/View/*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-organization-gzc-view-$',
      index: undefined,
      id: 'Admin/Organization/Gzc/View/*',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'UserObjectManager',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-userobjectmanager-index" */ '@/pages/Admin/UserObjectManager/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/UserObjectManager',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/UserObjectManager',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-userobjectmanager-index',
      index: true,
      id: 'Admin/UserObjectManager',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'ResourcesDic/Operation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-resourcesdic-operation" */ '@/pages/Admin/ResourcesDic/Operation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/ResourcesDic/Operation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/ResourcesDic/Operation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-resourcesdic-operation',
      index: undefined,
      id: 'Admin/ResourcesDic/Operation',
      exact: true,
      exports: ["default"],
    },{
      path: 'SystemConfig/Operation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-systemconfig-operation" */ '@/pages/Admin/SystemConfig/Operation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/SystemConfig/Operation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/SystemConfig/Operation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-systemconfig-operation',
      index: undefined,
      id: 'Admin/SystemConfig/Operation',
      exact: true,
      exports: ["default"],
    },{
      path: 'RegisterLisence',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-registerlisence-index" */ '@/pages/Admin/RegisterLisence/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/RegisterLisence',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/RegisterLisence',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-registerlisence-index',
      index: true,
      id: 'Admin/RegisterLisence',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'ResourcesDic/Detail/*',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-resourcesdic-detail-$" */ '@/pages/Admin/ResourcesDic/Detail/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/ResourcesDic/Detail/*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/ResourcesDic/Detail/*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-resourcesdic-detail-$',
      index: undefined,
      id: 'Admin/ResourcesDic/Detail/*',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Dictionary/Operation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-dictionary-operation" */ '@/pages/Admin/Dictionary/Operation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Dictionary/Operation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Dictionary/Operation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-dictionary-operation',
      index: undefined,
      id: 'Admin/Dictionary/Operation',
      exact: true,
      exports: ["default"],
    },{
      path: 'Menu/DialogOperation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-menu-dialogoperation" */ '@/pages/Admin/Menu/DialogOperation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Menu/DialogOperation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Menu/DialogOperation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-menu-dialogoperation',
      index: undefined,
      id: 'Admin/Menu/DialogOperation',
      exact: true,
      exports: ["default"],
    },{
      path: 'Role/DialogOperation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-role-dialogoperation" */ '@/pages/Admin/Role/DialogOperation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Role/DialogOperation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Role/DialogOperation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-role-dialogoperation',
      index: undefined,
      id: 'Admin/Role/DialogOperation',
      exact: true,
      exports: ["default"],
    },{
      path: 'Users/List/Operation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-users-list-operation" */ '@/pages/Admin/Users/List/Operation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Users/List/Operation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Users/List/Operation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-users-list-operation',
      index: undefined,
      id: 'Admin/Users/List/Operation',
      exact: true,
      exports: ["default"],
    },{
      path: 'Users/EditProfile/*',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-users-editprofile-$" */ '@/pages/Admin/Users/EditProfile/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Users/EditProfile/*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Users/EditProfile/*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-users-editprofile-$',
      index: undefined,
      id: 'Admin/Users/EditProfile/*',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Dictionary/actions',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-dictionary-actions" */ '@/pages/Admin/Dictionary/actions');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Dictionary/actions',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Dictionary/actions',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-dictionary-actions',
      index: undefined,
      id: 'Admin/Dictionary/actions',
      exact: true,
      exports: ["AddData","CleanCache","DeleteData","SaveData","renderIcon","renderSort","renderStatus"],
    },{
      path: 'Function/Operation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-function-operation" */ '@/pages/Admin/Function/Operation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Function/Operation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Function/Operation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-function-operation',
      index: undefined,
      id: 'Admin/Function/Operation',
      exact: true,
      exports: ["default"],
    },{
      path: 'ResourcesDic',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-resourcesdic-index" */ '@/pages/Admin/ResourcesDic/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/ResourcesDic',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/ResourcesDic',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-resourcesdic-index',
      index: true,
      id: 'Admin/ResourcesDic',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'SystemConfig',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-systemconfig-index" */ '@/pages/Admin/SystemConfig/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/SystemConfig',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/SystemConfig',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-systemconfig-index',
      index: true,
      id: 'Admin/SystemConfig',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'SendMessage',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-sendmessage-index" */ '@/pages/Admin/SendMessage/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/SendMessage',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/SendMessage',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-sendmessage-index',
      index: true,
      id: 'Admin/SendMessage',
      exact: true,
      exports: ["default"],
    },{
      path: 'Dictionary/Items',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-dictionary-items" */ '@/pages/Admin/Dictionary/Items');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Dictionary/Items',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Dictionary/Items',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-dictionary-items',
      index: undefined,
      id: 'Admin/Dictionary/Items',
      exact: true,
      exports: ["default"],
    },{
      path: 'Dictionary',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-dictionary-index" */ '@/pages/Admin/Dictionary/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Dictionary',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Dictionary',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-dictionary-index',
      index: true,
      id: 'Admin/Dictionary',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Users/List',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-users-list-index" */ '@/pages/Admin/Users/List/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Users/List',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Users/List',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-users-list-index',
      index: true,
      id: 'Admin/Users/List',
      exact: true,
      exports: ["default"],
    },{
      path: 'Function',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-function-index" */ '@/pages/Admin/Function/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Function',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Function',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-function-index',
      index: true,
      id: 'Admin/Function',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Menu/Operation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-menu-operation" */ '@/pages/Admin/Menu/Operation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Menu/Operation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Menu/Operation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-menu-operation',
      index: undefined,
      id: 'Admin/Menu/Operation',
      exact: true,
      exports: ["default"],
    },{
      path: 'Role/Operation',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-role-operation" */ '@/pages/Admin/Role/Operation');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Role/Operation',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Role/Operation',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-role-operation',
      index: undefined,
      id: 'Admin/Role/Operation',
      exact: true,
      exports: ["default"],
    },{
      path: 'Logs/ApiLog',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-logs-apilog" */ '@/pages/Admin/Logs/ApiLog');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Logs/ApiLog',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Logs/ApiLog',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-logs-apilog',
      index: undefined,
      id: 'Admin/Logs/ApiLog',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Logs/AppLog',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-logs-applog" */ '@/pages/Admin/Logs/AppLog');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Logs/AppLog',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Logs/AppLog',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-logs-applog',
      index: undefined,
      id: 'Admin/Logs/AppLog',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Menu',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-menu-index" */ '@/pages/Admin/Menu/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Menu',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Menu',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-menu-index',
      index: true,
      id: 'Admin/Menu',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Role',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-role-index" */ '@/pages/Admin/Role/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/Role',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/Role',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-role-index',
      index: true,
      id: 'Admin/Role',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'SysInfo',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_admin-sysinfo" */ '@/pages/Admin/SysInfo');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Admin/SysInfo',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Admin/SysInfo',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'admin-sysinfo',
      index: undefined,
      id: 'Admin/SysInfo',
      exact: true,
      exports: ["default","pageConfig"],
    },]
  },{
    path: 'Event',
    async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-layout" */ '@/pages/Event/layout');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/layout',
          isLayout: true,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/layout',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
    errorElement: <RouteErrorComponent />,
    componentName: 'event-layout',
    index: undefined,
    id: 'Event/layout',
    exact: true,
    exports: ["default"],
    layout: true,
    children: [{
      path: 'Conflict/BasicForm',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-conflict-basicform" */ '@/pages/Event/Conflict/BasicForm');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/Conflict/BasicForm',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/Conflict/BasicForm',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-conflict-basicform',
      index: undefined,
      id: 'Event/Conflict/BasicForm',
      exact: true,
      exports: ["default"],
    },{
      path: 'Petition/BasicForm',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-petition-basicform" */ '@/pages/Event/Petition/BasicForm');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/Petition/BasicForm',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/Petition/BasicForm',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-petition-basicform',
      index: undefined,
      id: 'Event/Petition/BasicForm',
      exact: true,
      exports: ["default"],
    },{
      path: 'General/BasicForm',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-general-basicform" */ '@/pages/Event/General/BasicForm');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/General/BasicForm',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/General/BasicForm',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-general-basicform',
      index: undefined,
      id: 'Event/General/BasicForm',
      exact: true,
      exports: ["default"],
    },{
      path: 'SafeHa/BasicForm',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-safeha-basicform" */ '@/pages/Event/SafeHa/BasicForm');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/SafeHa/BasicForm',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/SafeHa/BasicForm',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-safeha-basicform',
      index: undefined,
      id: 'Event/SafeHa/BasicForm',
      exact: true,
      exports: ["default"],
    },{
      path: 'Conflict/Edit/*',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-conflict-edit-$" */ '@/pages/Event/Conflict/Edit/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/Conflict/Edit/*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/Conflict/Edit/*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-conflict-edit-$',
      index: undefined,
      id: 'Event/Conflict/Edit/*',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Conflict/View/*',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-conflict-view-$" */ '@/pages/Event/Conflict/View/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/Conflict/View/*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/Conflict/View/*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-conflict-view-$',
      index: undefined,
      id: 'Event/Conflict/View/*',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Petition/Edit/*',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-petition-edit-$" */ '@/pages/Event/Petition/Edit/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/Petition/Edit/*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/Petition/Edit/*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-petition-edit-$',
      index: undefined,
      id: 'Event/Petition/Edit/*',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Petition/View/*',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-petition-view-$" */ '@/pages/Event/Petition/View/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/Petition/View/*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/Petition/View/*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-petition-view-$',
      index: undefined,
      id: 'Event/Petition/View/*',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'General/Edit/*',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-general-edit-$" */ '@/pages/Event/General/Edit/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/General/Edit/*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/General/Edit/*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-general-edit-$',
      index: undefined,
      id: 'Event/General/Edit/*',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'General/View/*',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-general-view-$" */ '@/pages/Event/General/View/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/General/View/*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/General/View/*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-general-view-$',
      index: undefined,
      id: 'Event/General/View/*',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'GetSearchParam',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-getsearchparam" */ '@/pages/Event/GetSearchParam');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/GetSearchParam',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/GetSearchParam',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-getsearchparam',
      index: undefined,
      id: 'Event/GetSearchParam',
      exact: true,
      exports: ["GetSearchParam"],
    },{
      path: 'Conflict/Deal',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-conflict-deal" */ '@/pages/Event/Conflict/Deal');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/Conflict/Deal',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/Conflict/Deal',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-conflict-deal',
      index: undefined,
      id: 'Event/Conflict/Deal',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Conflict/List',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-conflict-list" */ '@/pages/Event/Conflict/List');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/Conflict/List',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/Conflict/List',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-conflict-list',
      index: undefined,
      id: 'Event/Conflict/List',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Petition/Deal',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-petition-deal" */ '@/pages/Event/Petition/Deal');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/Petition/Deal',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/Petition/Deal',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-petition-deal',
      index: undefined,
      id: 'Event/Petition/Deal',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Petition/List',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-petition-list" */ '@/pages/Event/Petition/List');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/Petition/List',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/Petition/List',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-petition-list',
      index: undefined,
      id: 'Event/Petition/List',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'SafeHa/Edit/*',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-safeha-edit-$" */ '@/pages/Event/SafeHa/Edit/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/SafeHa/Edit/*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/SafeHa/Edit/*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-safeha-edit-$',
      index: undefined,
      id: 'Event/SafeHa/Edit/*',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'SafeHa/View/*',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-safeha-view-$" */ '@/pages/Event/SafeHa/View/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/SafeHa/View/*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/SafeHa/View/*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-safeha-view-$',
      index: undefined,
      id: 'Event/SafeHa/View/*',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'General/Deal',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-general-deal" */ '@/pages/Event/General/Deal');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/General/Deal',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/General/Deal',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-general-deal',
      index: undefined,
      id: 'Event/General/Deal',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'General/List',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-general-list" */ '@/pages/Event/General/List');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/General/List',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/General/List',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-general-list',
      index: undefined,
      id: 'Event/General/List',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'SafeHa/Deal',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-safeha-deal" */ '@/pages/Event/SafeHa/Deal');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/SafeHa/Deal',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/SafeHa/Deal',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-safeha-deal',
      index: undefined,
      id: 'Event/SafeHa/Deal',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'SafeHa/List',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-safeha-list" */ '@/pages/Event/SafeHa/List');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/SafeHa/List',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/SafeHa/List',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-safeha-list',
      index: undefined,
      id: 'Event/SafeHa/List',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'ZrrForm',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_event-zrrform" */ '@/pages/Event/ZrrForm');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Event/ZrrForm',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Event/ZrrForm',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'event-zrrform',
      index: undefined,
      id: 'Event/ZrrForm',
      exact: true,
      exports: ["default"],
    },]
  },{
    path: 'Feedback/401',
    async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_feedback-401" */ '@/pages/Feedback/401');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Feedback/401',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Feedback/401',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
    errorElement: <RouteErrorComponent />,
    componentName: 'feedback-401',
    index: undefined,
    id: 'Feedback/401',
    exact: true,
    exports: ["default","pageConfig"],
  },{
    path: 'Feedback/404',
    async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_feedback-404" */ '@/pages/Feedback/404');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Feedback/404',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Feedback/404',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
    errorElement: <RouteErrorComponent />,
    componentName: 'feedback-404',
    index: undefined,
    id: 'Feedback/404',
    exact: true,
    exports: ["default","pageConfig"],
  },{
    path: 'Feedback/405',
    async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_feedback-405" */ '@/pages/Feedback/405');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Feedback/405',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Feedback/405',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
    errorElement: <RouteErrorComponent />,
    componentName: 'feedback-405',
    index: undefined,
    id: 'Feedback/405',
    exact: true,
    exports: ["default","pageConfig"],
  },{
    path: 'Feedback/500',
    async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_feedback-500" */ '@/pages/Feedback/500');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Feedback/500',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Feedback/500',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
    errorElement: <RouteErrorComponent />,
    componentName: 'feedback-500',
    index: undefined,
    id: 'Feedback/500',
    exact: true,
    exports: ["default","pageConfig"],
  },{
    path: 'Users',
    async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_users-layout" */ '@/pages/Users/layout');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Users/layout',
          isLayout: true,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Users/layout',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
    errorElement: <RouteErrorComponent />,
    componentName: 'users-layout',
    index: undefined,
    id: 'Users/layout',
    exact: true,
    exports: ["default"],
    layout: true,
    children: [{
      path: 'OAuth/WeChat/Mobile',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_users-oauth-wechat-mobile-index" */ '@/pages/Users/OAuth/WeChat/Mobile/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Users/OAuth/WeChat/Mobile',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Users/OAuth/WeChat/Mobile',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'users-oauth-wechat-mobile-index',
      index: true,
      id: 'Users/OAuth/WeChat/Mobile',
      exact: true,
      exports: ["default"],
    },{
      path: 'Profile/ThirdLogin',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_users-profile-thirdlogin" */ '@/pages/Users/Profile/ThirdLogin');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Users/Profile/ThirdLogin',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Users/Profile/ThirdLogin',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'users-profile-thirdlogin',
      index: undefined,
      id: 'Users/Profile/ThirdLogin',
      exact: true,
      exports: ["default"],
    },{
      path: 'EditProfile/:id',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_users-editprofile-$id" */ '@/pages/Users/EditProfile/$id');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Users/EditProfile/:id',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Users/EditProfile/:id',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'users-editprofile-$id',
      index: undefined,
      id: 'Users/EditProfile/:id',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Messenger',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_users-messenger-index" */ '@/pages/Users/Messenger/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Users/Messenger',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Users/Messenger',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'users-messenger-index',
      index: true,
      id: 'Users/Messenger',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Profile',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_users-profile-index" */ '@/pages/Users/Profile/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Users/Profile',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Users/Profile',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'users-profile-index',
      index: true,
      id: 'Users/Profile',
      exact: true,
      exports: ["default"],
    },{
      path: 'OAuth2',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_users-oauth2-index" */ '@/pages/Users/OAuth2/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Users/OAuth2',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Users/OAuth2',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'users-oauth2-index',
      index: true,
      id: 'Users/OAuth2',
      exact: true,
      exports: ["default"],
    },]
  },{
    path: '',
    async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_index-layout" */ '@/pages/index/layout');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'layout',
          isLayout: true,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'layout',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
    errorElement: <RouteErrorComponent />,
    componentName: 'index-layout',
    index: undefined,
    id: 'layout',
    exact: true,
    exports: ["default"],
    layout: true,
    children: [{
      path: '',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_index-index" */ '@/pages/index/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: '/',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: '/',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'index-index',
      index: true,
      id: '/',
      exact: true,
      exports: ["default","pageConfig"],
    },]
  },{
    path: 'Auth',
    async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_auth-layout" */ '@/pages/Auth/layout');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Auth/layout',
          isLayout: true,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Auth/layout',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
    errorElement: <RouteErrorComponent />,
    componentName: 'auth-layout',
    index: undefined,
    id: 'Auth/layout',
    exact: true,
    exports: ["default"],
    layout: true,
    children: [{
      path: 'Logout',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_auth-logout" */ '@/pages/Auth/Logout');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Auth/Logout',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Auth/Logout',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'auth-logout',
      index: undefined,
      id: 'Auth/Logout',
      exact: true,
      exports: ["default","pageConfig"],
    },{
      path: 'Login',
      async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_auth-login" */ '@/pages/Auth/Login');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Auth/Login',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Auth/Login',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
      errorElement: <RouteErrorComponent />,
      componentName: 'auth-login',
      index: undefined,
      id: 'Auth/Login',
      exact: true,
      exports: ["default","pageConfig"],
    },]
  },{
    path: 'login',
    async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_login-index" */ '@/pages/login/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'login',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'login',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
    errorElement: <RouteErrorComponent />,
    componentName: 'login-index',
    index: true,
    id: 'login',
    exact: true,
    exports: ["default","pageConfig"],
  },{
    path: 'Feedback/*',
    async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_feedback-$" */ '@/pages/Feedback/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'Feedback/*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'Feedback/*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
    errorElement: <RouteErrorComponent />,
    componentName: 'feedback-$',
    index: undefined,
    id: 'Feedback/*',
    exact: true,
    exports: ["default","pageConfig"],
  },{
    path: 'form',
    async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_form-index" */ '@/pages/form/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'form',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'form',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
    errorElement: <RouteErrorComponent />,
    componentName: 'form-index',
    index: true,
    id: 'form',
    exact: true,
    exports: ["default","pageConfig"],
  },{
    path: 'list',
    async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_list-index" */ '@/pages/list/index');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: 'list',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: 'list',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
    errorElement: <RouteErrorComponent />,
    componentName: 'list-index',
    index: true,
    id: 'list',
    exact: true,
    exports: ["default","pageConfig"],
  },{
    path: '*',
    async lazy() {
      const componentModule = await import(/* webpackChunkName: "p_$" */ '@/pages/$');
      return {
        ...componentModule,
        Component: () => WrapRouteComponent({
          routeId: '*',
          isLayout: false,
          routeExports: componentModule,
        }),
        loader: createRouteLoader({
          routeId: '*',
          requestContext,
          renderMode,
          module: componentModule,
        }),
      };
    },
    errorElement: <RouteErrorComponent />,
    componentName: '$',
    index: undefined,
    id: '*',
    exact: true,
    exports: ["default","pageConfig"],
  },
]);
export default createRoutes;
